import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, inject, Input, OnInit, signal } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { Carriers } from 'app/shared/enum/general-enum';
import { ICountry } from 'app/shared/models/country.interface';
import { ICustomerCarrier } from 'app/shared/models/customer-carrier.interface';
import { Customer } from 'app/shared/models/customer/customer.model';
import { ElementBlockerModule } from '../shared/components/element-blocker/element-blocker.module';
import { User } from '../shared/services/user/models/user.model';
import { LookupService } from 'app/shared/services/lookup/lookup.service';
import { UtilityService } from 'app/shared/services/utility/utility.service';
import { ShipmentService } from 'app/ship/services/shipment.service';
import * as _ from 'lodash';
import { IReportShipmentEU } from 'app/history/report-history/report-shipment-eu-dialog/models/report-shipment-eu.interface';
import { UserService } from 'app/shared/services/user/user.service';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { IReportService } from 'app/history/models/report-service.interface';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { ValidationService } from 'app/shared/services/validation/validation.service';
import { ReportShipmentService } from 'app/history/services/report-shipment.service';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { UserTermsConditionsComponent } from 'app/account/user-settings/user-terms-conditions/user-terms-conditions.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from 'app/shared/models/notification-type';
import { MatButton } from '@angular/material/button';
import { S3FileDownloaderModule } from '../shared/components/s3-file-downloader/s3-file-downloader.module';
import { MatDatepicker, MatDatepickerInput, MatDatepickerIntl, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import { SignUpDropdowns } from 'config/dropdown-config';
import { Router } from '@angular/router';

@Component({
  selector: 'upsc-report-shipments-single-upload',
  templateUrl: './report-shipments-single-upload.component.html',
  styleUrls: ['./report-shipments-single-upload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    TranslateModule,
    MatFormField,
    MatSelect,
    MatLabel,
    MatError,
    MatOption,
    ElementBlockerModule,
    MatInput,
    MatError,
    MatCheckbox,
    MatButton,
    MatDatepickerToggle,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    S3FileDownloaderModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }]
})
export class ReportShipmentsSingleUploadComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public user: User;
  public customer: Customer;
  public userCurrency = 'USD';
  public maxInsuredValue = -1;
  public isPostalCodeAware = true;

  public shipmentTypes = [
    {
      shipmentTypeCode: '1',
      shipmentTypeName: 'Insurance Only',
    },
    {
      shipmentTypeCode: '2',
      shipmentTypeName: 'Freight & Insurance',
    },
  ];
  public carriers: ICustomerCarrier[];
  public destinations = ['Domestic', 'International'];
  public serviceTypes: IReportService[];
  public filteredServices: IReportService[];
  public countries: ICountry[];

  public isCountriesLoading = false;
  public isCarriersLoading = false;
  public isServiceTypesLoading = false;

  public trackingNumberValidationSubscription: Subscription;
  public isTrackingNumberValidating = false;
  public isTrackingNumberValid = false;
  public isEditingExistingData = false;

  public minDate: Date = new Date();
  public maxDate: Date = new Date();

  @Input() public rowToEdit: IReportShipmentEU;

  private selectedCarrierCode: string;
  private selectedServiceType: string;

  private getReportServiceSubscription: Subscription;

  public states = [];

  public signUpDropdowns = SignUpDropdowns;
  public isInvaliDHLCountry: boolean = false;


  public constructor(private readonly formBuilder: UntypedFormBuilder,
    public readonly appState: AppState,
    private readonly lookupService: LookupService,
    private readonly shipmentService: ShipmentService,
    private readonly utilityService: UtilityService,
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
    private readonly validationService: ValidationService,
    private readonly reportShipmentService: ReportShipmentService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportShipmentsSingleUploadComponent>,
    private spinnerService: SpinnerService,
    private router: Router
  ) {
    this.customer = this.appState.customer$();
    this.user = this.appState.user$();
    this.loadInitialValues();
  }

  // Date picker localization variables
  private readonly _datePickerAdapter = inject<DateAdapter<unknown, unknown>>(DateAdapter);
  private readonly _intl = inject(MatDatepickerIntl);
  private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));

  public ngOnInit(): void {
    this.isEditingExistingData = this.rowToEdit !== undefined;

    // Set up date picker localization based on domain    
    const currentDomainName = this.utilityService.getCurrentDomainName();
    if (currentDomainName.includes('upscapitalde.com') ||
      currentDomainName.includes('upscapitaluk.com') ||
      currentDomainName.includes('upscapitalfr.com') ||
      currentDomainName.includes('upscapitalit.com')) {
      this.changeDatePickerLocalization('en-GB');
    }
    else {
      this.changeDatePickerLocalization('en-US');
    }

    // Pull states based on country for US and CA only
    if (this.user.CountryCode.toUpperCase() === 'US' || this.user.CountryCode.toUpperCase() === 'CA') {
      this.states = this.signUpDropdowns.states.filter(x => x.country === this.user.CountryCode);
    }

    if (this.user && this.userService.isEURUser(this.user.CountryCode)) {
      this.userCurrency = 'EUR';
    } else if (this.user && this.userService.isGBUser(this.user)) {
      this.userCurrency = 'GBP';
    }

    const isAgreeToTermsValidators = this.rowToEdit ? [] : [Validators.requiredTrue];

    // FI-743: System not allowing users to select date from past 30 days
    this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 30, 0, 0, 0);

    this.formGroup = this.formBuilder.group({
      userId: this.user.UserId,
      customerId: this.customer.CustomerId,

      generalInformation: this.formBuilder.group({
        shipDate: [null, [Validators.required, Validators.min(this.minDate.getTime()), Validators.max(this.maxDate.getTime())]],
        carrier: ['', Validators.compose([Validators.required])],
        serviceType: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
        coverage: ['', {
          validators: [
            Validators.required,
            this.coverageValidator(),
          ],
          updateOn: 'change'
        }],
        coverageCurrency: [this.userCurrency, Validators.compose([Validators.required])],
        trackingNumber: new FormControl('', {
          validators: [
            Validators.required,
            this.trackingNumberFormatValidator()
          ],
          asyncValidators: [this.trackingNumberValidator.bind(this)],
        }),
        shipmentType: ['', Validators.compose([Validators.required])],
        shipFromLocation: ['', Validators.compose([Validators.required])],
        shipToLocation: ['', Validators.compose([Validators.required])],
      }, { updateOn: 'blur' }),
    },);

    this.addConditionalGroup(this.isEditingExistingData, isAgreeToTermsValidators);
    this.monitorValueChanges();

    if (this.isEditingExistingData) {
      this.selectedCarrierCode = this.rowToEdit.Carrier?.toUpperCase();
      this.selectedServiceType = this.rowToEdit.Service;
      this.formGroup.patchValue({
        generalInformation: {
          shipDate: this.rowToEdit.ShipDate,
          carrier: this.rowToEdit.Carrier?.toUpperCase(),
          serviceType: this.rowToEdit.Service,
          coverage: this.rowToEdit.Coverage,
          trackingNumber: this.rowToEdit.TrackingNumber,
          shipmentType: this.rowToEdit.ShipmentType?.toString(),
          shipFromLocation: this.rowToEdit.ShipFromCountry,
          shipToLocation: this.rowToEdit.ShipToCountry,
        },
        shippingInformation: {
          weight: this.rowToEdit.Weight,
        },
        shipToAddress: {
          shipToCompanyName: this.rowToEdit.ShipToCompanyName,
          shipToAttention: this.rowToEdit.ShipToLastName,
          shipToAddress1: this.rowToEdit.ShipToAddress1,
          shipToAddress2: this.rowToEdit.ShipToAddress2,
          shipToAddress3: this.rowToEdit.ShipToAddress3,
          shipToCity: this.rowToEdit.ShipToCity,
          shipToStateProvince: this.rowToEdit.ShipToProvince,
          shipToZipCode: this.rowToEdit.ShipToZip,
          shipToPhone: this.rowToEdit.ShipToPhone,
          shipToEmail: this.rowToEdit.ShipToEmail,
        },
        shipFromAddress: {
          shipFromCompanyName: this.rowToEdit.ShipFromCompanyName,
          shipFromAttention: this.rowToEdit.ShipFromContactName,
          shipFromAddress1: this.rowToEdit.ShipFromAddress1,
          shipFromAddress2: this.rowToEdit.ShipFromAddress2,
          shipFromAddress3: this.rowToEdit.ShipFromAddress3,
          shipFromCity: this.rowToEdit.ShipFromCity,
          shipFromStateProvince: this.rowToEdit.ShipFromProvince,
          shipFromZipCode: this.rowToEdit.ShipFromZip,
          shipFromPhone: this.rowToEdit.ShipFromPhone,
          shipFromEmail: this.rowToEdit.ShipFromEmail
        },
      });

      this.onServiceTypeChange(this.rowToEdit?.Service);
      this.formGroup.markAllAsTouched();
    }

    this.formGroup.statusChanges.subscribe(() => this.onFormGroupChanges());
  }

  private changeDatePickerLocalization(region: string): void {
    this._locale.set(region);
    this._datePickerAdapter.setLocale(this._locale());
    this._intl.changes.next();
  }

  public addConditionalGroup(isEditingExistingData, isAgreeToTermsValidators): void {
    if (isEditingExistingData) {

      this.formGroup.addControl('shippingInformation', this.formBuilder.group({
        weight: ['', [this.positiveNumberValidator()]],
      }));

      this.formGroup.addControl('shipToAddress', this.formBuilder.group({
        shipToCompanyName: [''],
        shipToAttention: [''],
        shipToAddress1: [''],
        shipToAddress2: [''],
        shipToAddress3: [''],
        shipToCity: [''],
        shipToStateProvince: [''],
        shipToZipCode: ['', Validators.compose([Validators.pattern(/^(|[a-zA-Z0-9\s\-]+)$/), Validators.maxLength(15)])],
        shipToPhone: ['', Validators.pattern(/^(|[0-9\-]+)$/)],
        shipToEmail: ['', Validators.email],
      }));

      this.formGroup.addControl('shipFromAddress', this.formBuilder.group({
        shipFromCompanyName: [''],
        shipFromAttention: [''],
        shipFromAddress1: [''],
        shipFromAddress2: [''],
        shipFromAddress3: [''],
        shipFromCity: [''],
        shipFromStateProvince: [''],
        shipFromZipCode: ['', Validators.compose([Validators.pattern(/^(|[a-zA-Z0-9\s\-]+)$/), Validators.maxLength(15)])],
        shipFromPhone: ['', Validators.pattern(/^(|[0-9\-]+)$/)],
        shipFromEmail: ['', Validators.email],
      }));

    } else {
      this.formGroup.addControl('shippingInformation', this.formBuilder.group({
        companyName: [''],
        phoneNumber: [''],
        firstName: [''],
        lastName: [''],
        shipToZipCode: ['', Validators.compose([Validators.pattern(/^(|[a-zA-Z0-9\s\-]+)$/), Validators.maxLength(15)])],
        province: [''],
        city: [''],
        weight: ['', [this.positiveNumberValidator()]],
        referenceNumber: [''],
      }, { updateOn: 'blur' }));
      this.formGroup.addControl('isAgreeToTerms', new FormControl(false, Validators.compose(isAgreeToTermsValidators)));
    }

    this.setConditionalValidators();
  }

  public setConditionalValidators(): void {
    const shippingInformationGroup = this.formGroup.get('shippingInformation') as FormGroup;
    const shipToZipCodeControl = shippingInformationGroup?.get('shipToZipCode');

    if (shipToZipCodeControl) {
      if (this.formGroup.get('generalInformation')?.get('shipToLocation')?.value === 'US') {
        shipToZipCodeControl.setValidators([Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)]);
      } else {
        shipToZipCodeControl.setValidators([Validators.pattern(/^(|[a-zA-Z0-9\s\-]+)$/), Validators.maxLength(15)]);
      }

      // Update the value and validity of the control
      shipToZipCodeControl.updateValueAndValidity();
    }
  }


  private loadInitialValues(): void {
    this.isCarriersLoading = true;
    this.lookupService.getCustomerCarriers(this.customer.CustomerId)
      .subscribe(
        (carriers) => {
          this.carriers = carriers;
          this.utilityService.delay(() => {
            this.isCarriersLoading = false;
          });
        },
      );

    this.isCountriesLoading = true;
    this.shipmentService.getShipToCountries(Carriers[Carriers.Ups])
      .subscribe(
        (countries) => {
          this.countries = _.cloneDeep(_.sortBy(countries, ['CountryName']));
          this.utilityService.delay(() => {
            this.isCountriesLoading = false;
          });
        },
      );
  }
  private monitorValueChanges(): void {
    (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.carrier.valueChanges
      .subscribe(
        (carrierCode) => {
          this.selectedCarrierCode = carrierCode?.toUpperCase();
          (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType.reset();
          this.getServiceTypes();
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType.valueChanges
      .subscribe(
        (serviceTypeCode) => {
          if (!serviceTypeCode || !this.serviceTypes) {
            return;
          }

          const selectedService = this.serviceTypes.find(item => item.ServiceCode === serviceTypeCode && item.CarrierCode?.toUpperCase() === this.selectedCarrierCode?.toUpperCase());
          if (!selectedService) {
            return;
          }

          this.setMaxInsuredValue(selectedService.ReportMaxCoverage);
          this.onServiceTypeChange(selectedService.ServiceCode);
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.shipFromLocation.valueChanges
      .subscribe(
        (_countryCode) => {
          this.getServiceTypes();
          this.updateCarriersIfDomestic();
          // If DHL is selected carrier, update service types based on country selections
          if (this.selectedCarrierCode?.toUpperCase() === 'DHL') {
            this.handleGetReportServicesSuccess(this.serviceTypes);
          }
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.shipToLocation.valueChanges
      .subscribe(
        (countryCode) => {
          this.getServiceTypes();
          this.updateCarriersIfDomestic();
          // If DHL is selected carrier, update service types based on country selections
          if (this.selectedCarrierCode?.toUpperCase() === 'DHL') {
            this.handleGetReportServicesSuccess(this.serviceTypes);
          }

          if (!countryCode || !this.countries || !this.countries.length) {
            return;
          }

          const country = this.countries.find(item => item.CountryCode === countryCode);
          if (!country) {
            return;
          }

          this.isPostalCodeAware = country.IsPostalCodeAware;
          const postalCodeForm = this.isEditingExistingData
            ? (<UntypedFormGroup>this.formGroup.controls.shipToAddress).controls.shipToZipCode
            : (<UntypedFormGroup>this.formGroup.controls.shippingInformation).controls.shipToZipCode;
          if (country.IsPostalCodeAware) {
            this.setConditionalValidators();
          } else {
            this.validationService.clearFormControlValidators([postalCodeForm]);
          }
        },
      );
  }

  private getServiceTypes(): void {
    const serviceTypeControl = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType;
    if (!this.selectedCarrierCode) {
      serviceTypeControl.setValue('');
      return;
    }

    this.isServiceTypesLoading = true;
    if (this.serviceTypes) {
      this.handleGetReportServicesSuccess(this.serviceTypes);
    } else {
      this.utilityService.clearSubscriptions([this.getReportServiceSubscription]);
      this.getReportServiceSubscription = this.reportShipmentService.getReportServices()
        .subscribe({
          next: services => this.handleGetReportServicesSuccess(services),
          error: err => this.handleGetReportServicesFailure(err),
        });
    }
  }

  private handleGetReportServicesSuccess(services: IReportService[]): void {
    const serviceTypeControl = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType;
    serviceTypeControl.enable();

    const carrierCode = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.carrier.value.toString();
    this.serviceTypes = services;
    const isDomestic = this.isDomestic() || this.isPuertoRicoCountry();
    this.filteredServices = _.cloneDeep(this.serviceTypes).filter(
      (service) => {
        return service.IsAllowed && carrierCode.toUpperCase() === service.CarrierCode.toUpperCase() && service.IsDomestic === isDomestic;
      },
    );

    this.isServiceTypesLoading = false;
    if (this.selectedCarrierCode && this.selectedServiceType) {
      const service = this.filteredServices.find(item => item.ServiceCode === this.selectedServiceType);
      if (service) {
        this.setMaxInsuredValue(service.ReportMaxCoverage);
      }
    }
  }

  private handleGetReportServicesFailure(err): void {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed Getting Carrier Services',
      NotificationType.ERROR);
    this.isServiceTypesLoading = false;
  }

  public onFormSubmit(event, form): void {
    event.preventDefault();
    this.spinnerService.show();

    const reportShipment: IReportShipmentEU = {
      ShipDate: form.generalInformation.shipDate,
      Carrier: form.generalInformation.carrier,
      Service: form.generalInformation.serviceType,
      Coverage: form.generalInformation.coverage,
      CoverageCurrencyCode: form.generalInformation.coverageCurrency,
      TrackingNumber: form.generalInformation.trackingNumber.replace(/\s/g, ''),
      ShipmentType: form.generalInformation.shipmentType,
      ShipFromCountry: form.generalInformation.shipFromLocation,
      ShipToCountry: form.generalInformation.shipToLocation,

      ShipToCompanyName: form.shippingInformation.companyName,
      ShipToFirstName: form.shippingInformation.firstName,
      ShipToLastName: form.shippingInformation.lastName,
      ShipToPhone: form.shippingInformation.phoneNumber,
      ShipToZip: form.shippingInformation.shipToZipCode,
      ShipToProvince: form.shippingInformation.province,
      ShipToCity: form.shippingInformation.city,
      Weight: this.userService.standardizeLocalizedNumber(this.user, form.shippingInformation.weight).toString(),
      Reference: form.shippingInformation.referenceNumber,

      MasterCustomerID: this.customer.CustomerId,
      UserId: form.userId,
      CustomerId: form.customerId,

    };

    this.reportShipmentService.saveReportShipmentEU(reportShipment)
      .subscribe({
        next: (result) => this.handleSaveReportShipmentEUSuccess(result),
        error: (err) => this.handleSaveReportShipmentEUFailure(err),
      });
  }

  private handleSaveReportShipmentEUSuccess(_result): void {
    this.notificationService.notify(
      'Shipment Reported Successfully',
      'Success',
      NotificationType.SUCCESS);

    this.router.navigate(['/shipment-history']);
    this.spinnerService.hide();
  }

  private handleSaveReportShipmentEUFailure(err): void {
    this.spinnerService.hide();

    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed saving report shipment',
      NotificationType.ERROR);
  }

  public isFormValid(): boolean {
    return this.formGroup.valid;
  }

  public openTermsAndConditionsDialog(event): void {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '70%',
      data: {},
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    const dialogRef = this.dialog.open(UserTermsConditionsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  private setMaxInsuredValue(value: number): void {
    // [MV3-2084] Limit maximum value for insured value in the message to 2 decimal places.
    this.maxInsuredValue = +value.toFixed(2);
  }

  private onFormGroupChanges(): void {
    if (this.formGroup.status === 'VALID') {
      const currentRowData: IReportShipmentEU = {
        ShipDate: this.formGroup.get('generalInformation.shipDate').value,
        Carrier: this.formGroup.get('generalInformation.carrier').value,
        Service: this.formGroup.get('generalInformation.serviceType').value,
        Coverage: this.formGroup.get('generalInformation.coverage').value,
        CoverageCurrencyCode: this.formGroup.get('generalInformation.coverageCurrency').value,
        TrackingNumber: this.formGroup.get('generalInformation.trackingNumber').value,
        ShipmentType: this.formGroup.get('generalInformation.shipmentType').value,
        ShipFromCountry: this.formGroup.get('generalInformation.shipFromLocation').value,
        ShipToCountry: this.formGroup.get('generalInformation.shipToLocation').value,

        ShipToCompanyName: this.isEditingExistingData
          ? this.formGroup.get('shipToAddress.shipToCompanyName').value
          : this.formGroup.get('shippingInformation.companyName').value,
        ShipToFirstName: this.isEditingExistingData
          ? ''
          : this.formGroup.get('shippingInformation.firstName').value,
        ShipToLastName: this.isEditingExistingData
          ? this.formGroup.get('shipToAddress.shipToAttention').value
          : this.formGroup.get('shippingInformation.lastName').value,
        ShipToPhone: this.isEditingExistingData
          ? this.formGroup.get('shipToAddress.shipToPhone').value
          : this.formGroup.get('shippingInformation.phoneNumber').value,
        ShipToZip: this.isEditingExistingData
          ? this.formGroup.get('shipToAddress.shipToZipCode').value
          : this.formGroup.get('shippingInformation.shipToZipCode').value,
        ShipToProvince: this.isEditingExistingData
          ? this.formGroup.get('shipToAddress.shipToStateProvince').value
          : this.formGroup.get('shippingInformation.province').value,
        ShipToCity: this.isEditingExistingData
          ? this.formGroup.get('shipToAddress.shipToCity').value
          : this.formGroup.get('shippingInformation.city').value,
        Weight: this.formGroup.get('shippingInformation.weight').value,
        Reference: this.isEditingExistingData
          ? ''
          : this.formGroup.get('shippingInformation.referenceNumber').value,

        MasterCustomerID: this.customer.CustomerId,
        UserId: this.user.UserId,
        CustomerId: this.customer.CustomerId,
      };

      if (this.isEditingExistingData) {
        currentRowData.ShipToAddress1 = this.formGroup.get('shipToAddress.shipToAddress1').value;
        currentRowData.ShipToAddress2 = this.formGroup.get('shipToAddress.shipToAddress2').value;
        currentRowData.ShipToAddress3 = this.formGroup.get('shipToAddress.shipToAddress3').value;
        currentRowData.ShipToEmail = this.formGroup.get('shipToAddress.shipToEmail').value;

        currentRowData.ShipFromCompanyName = this.formGroup.get('shipFromAddress.shipFromCompanyName').value;
        currentRowData.ShipFromContactName = this.formGroup.get('shipFromAddress.shipFromAttention').value;
        currentRowData.ShipFromAddress1 = this.formGroup.get('shipFromAddress.shipFromAddress1').value;
        currentRowData.ShipFromAddress2 = this.formGroup.get('shipFromAddress.shipFromAddress2').value;
        currentRowData.ShipFromAddress3 = this.formGroup.get('shipFromAddress.shipFromAddress3').value;
        currentRowData.ShipFromCity = this.formGroup.get('shipFromAddress.shipFromCity').value;
        currentRowData.ShipFromProvince = this.formGroup.get('shipFromAddress.shipFromStateProvince').value;
        currentRowData.ShipFromZip = this.formGroup.get('shipFromAddress.shipFromZipCode').value;
        currentRowData.ShipFromPhone = this.formGroup.get('shipFromAddress.shipFromPhone').value;
        currentRowData.ShipFromEmail = this.formGroup.get('shipFromAddress.shipFromEmail').value;
      }

      this.reportShipmentService.rowAfterEdit$.next({ data: currentRowData, isValid: true });
    } else if (this.formGroup.status === 'INVALID') {
      this.reportShipmentService.rowAfterEdit$.next({ data: null, isValid: false });
      this.logInvalidControls();
    }
  }

  private logInvalidControls(): void {
    const invalidControls = [];

    const logGroupControls = (group: FormGroup, parentName: string) => {
      Object.keys(group.controls).forEach(name => {
        const control = group.controls[name];
        const controlPath = parentName ? `${parentName}.${name}` : name;

        if (control instanceof FormGroup) {
          logGroupControls(control, controlPath);
        } else if (control.status === 'INVALID') {
          invalidControls.push(controlPath);
        }
      });
    };

    logGroupControls(this.formGroup, '');

    console.log('Invalid controls:', invalidControls);
  }

  private trackingNumberValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.reportShipmentService.validateTrackingNumber(control.value).pipe(
      map(() => {
        // If the service call succeeds, the tracking number is valid
        return null; // No errors, validation passed
      }),
      catchError(err => {
        if (err.status === 500 && err.error && err.error.Code === 7) {
          // Tracking number already exists
          this.reportShipmentService.rowAfterEdit$.next({ data: null, isValid: false });
          return of({ existed: true });
        } else {
          // Some other error occurred
          console.error('Error in trackingNumberValidator', err);
          return of({ serverError: true });
        }
      })
    );
  }

  private positiveNumberValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      // Regular expression to match a positive number (integer or decimal)
      const pattern = /^[+]?(\d+(\.\d+)?|\.\d+)$/;

      // Test the control value against the pattern
      if (!pattern.test(value)) {
        return { positiveNumber: true };
      }

      // Parse the value to a number
      const num = parseFloat(value);

      // Check if the number is greater than zero
      if (num <= 0) {
        return { positiveNumber: true };
      }

      return null; // Valid input
    };
  }

  private coverageValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === undefined || value === null || value.toString().trim() === '') {
        return { required: true };
      }

      if (isNaN(value) || typeof value === 'string' && !/^-?\d*\.?\d+$/.test(value)) {
        return { numeric: true };
      }

      const numericValue = parseFloat(value);
      if (numericValue <= 0) {
        return { positive: true };
      }

      if (numericValue > this.maxInsuredValue && this.maxInsuredValue != -1) {
        return { maxLimit: true };
      }

      return null;
    };
  }

  private onServiceTypeChange(serviceType: string | null): void {
    const coverageControl = this.formGroup.get('generalInformation.coverage');
    const isDomestic = this.isDomestic() || this.isPuertoRicoCountry();
    let clearCoverageControlValidator: boolean = false;

    this.getReportServiceSubscription = this.reportShipmentService.getReportServices().subscribe(
      (result) => {
        for (const element of result) {
          if (element.ServiceCode === serviceType) {
            if (isDomestic !== element.IsDomestic) {
              clearCoverageControlValidator = true;
            }
          }
        }
        if (!serviceType || clearCoverageControlValidator) {
          coverageControl?.clearValidators();
        }
        else {
          coverageControl?.setValidators([Validators.required, this.coverageValidator()]);
        }

        coverageControl?.updateValueAndValidity();
      }
    )
  }

  private isPuertoRicoCountry(): boolean {
    // FI-749: PuertoRico is considered a hybrid, so it counts as domestic NOT international
    const shipToLocation = this.formGroup.get('generalInformation.shipToLocation')?.value;
    const shipFromLocation = this.formGroup.get('generalInformation.shipFromLocation')?.value;
    if (shipToLocation === 'PR' || shipFromLocation === 'PR') {
      return true;
    }

    return false;
  }

  private isDomestic(): boolean {
    const shipToLocationControl = this.formGroup.get('generalInformation.shipToLocation')?.value;
    const shipFromLocationControl = this.formGroup.get('generalInformation.shipFromLocation')?.value;
    return shipToLocationControl === shipFromLocationControl;
  }

  private updateCarriersIfDomestic(): void {
    if (this.isDomestic()) {
      this.getReportServiceSubscription = this.reportShipmentService.getReportServices().subscribe(
        (result) => {
          this.carriers = this.carriers.filter(carrier => {
            const hasDomesticService  = result.some(r => r.CarrierCode === carrier.CarrierCode && r.IsDomestic);
            return hasDomesticService ;
          });
        });
    }
    else {
      this.lookupService.getCustomerCarriers(this.customer.CustomerId)
        .subscribe(
          (carriers) => {
            this.carriers = carriers;
            this.utilityService.delay(() => {
              this.isCarriersLoading = false;
            });
          },
        );
    }
  }

  private trackingNumberFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      
      if (!value) {
        return null; // Let required validator handle empty values
      }
  
      // Only allow letters and numbers (alphanumeric characters)
      if (!/^[a-zA-Z0-9]+$/.test(value)) {
        return { invalidFormat: true };
      }
  
      return null;
    };
  }
}
