<div>
    <ng-container [ngTemplateOutlet]="form"></ng-container>
</div>

<ng-template #form>

    <form [formGroup]="formGroup" class="report-shipments-single-upload" *ngIf="!isEditingExistingData"
        (ngSubmit)="onFormSubmit($event, formGroup.value)">

        {{ 'pleaseNote' | translate}}
        <strong>{{ 'reportShipment.notification1' | translate }}</strong><br>{{ 'reportShipment.notification2' |
        translate }}

        <div class="form-wrapper">
            <div class="form-container general-info" [formGroup]="formGroup.controls.generalInformation">
                <h4 class="section-title">General Information</h4>

                <mat-form-field class="form-group">
                    <mat-label>{{'ShipDate' | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="shipDate" required [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.touched && formGroup.controls.generalInformation?.controls.shipDate?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.errors?.required">
                            <span>{{'ShipDateRequired' | translate}}</span>
                        </span>
                        <span
                            *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.errors?.matDatepickerMin">
                            <span>{{'ShipDateMinError' | translate}}</span>
                        </span>
                        <span
                            *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.errors?.matDatepickerMax">
                            <span>{{'ShipDateMaxError' | translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <div class="inner-container">
                    <mat-form-field class="form-group">
                        <mat-label>Ship From Country/Territory</mat-label>
                        <mat-select required formControlName="shipFromLocation">
                            <mat-option value="" disabled selected>Select Ship From Country/Territory </mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                {{ country.CountryName }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.generalInformation?.controls.shipFromLocation?.touched">
                            <span *ngIf="formGroup.controls.generalInformation?.controls.shipFromLocation?.errors?.required">
                                Ship From Country/Territory is required
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-group">
                        <mat-label>Ship To Country/Territory</mat-label>
                        <mat-select required formControlName="shipToLocation">
                            <mat-option value="" disabled selected>Select Ship To Country/Territory </mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                {{ country.CountryName }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.generalInformation?.controls.shipToLocation?.touched">
                            <span *ngIf="formGroup.controls.generalInformation?.controls.shipToLocation?.errors?.required">
                                Ship To Country/Territory is required
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field class="form-group" [class.invalid-dhl-country]="isInvaliDHLCountry">
                    <mat-label>{{'Carrier' | translate}}</mat-label>
                    <mat-select required formControlName="carrier">
                        <mat-option value="" disabled selected>{{'reportShipment.selectCarrier'|translate}}</mat-option>
                        <mat-option *ngFor="let carrier of carriers" [value]="carrier.CarrierCode">
                            {{ carrier.CarrierCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.carrier?.touched && formGroup.controls.generalInformation?.controls.carrier?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.carrier?.errors?.required">
                            <span>{{'reportShipment.carrierRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isCarriersLoading"></upsc-element-blocker>

                <mat-form-field class="form-group">
                    <mat-label>{{'ServiceType'|translate}}</mat-label>
                    <mat-select required formControlName="serviceType">
                        <mat-option value="" selected>{{'reportShipment.selectServiceType'|translate}}</mat-option>
                        <mat-option *ngFor="let service of filteredServices" [value]="service.ServiceCode">
                            {{ service.Description || service.ServiceCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.serviceType?.touched && formGroup.controls.generalInformation?.controls.serviceType?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.serviceType?.errors?.required">
                            <span>{{'ServiceTypeRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-group">
                    <mat-label>Coverage</mat-label>
                    <input required matInput formControlName="coverage">
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.coverage.touched && formGroup.controls.generalInformation?.controls.coverage.invalid">
                        <span *ngIf="formGroup.get('generalInformation.coverage').errors?.required">
                            The insured value cannot be zero, negative, or blank.
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.coverage').errors?.numeric">
                            The insured value must be numeric.
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.coverage').errors?.positive">
                            The insured value cannot be zero, negative, or blank.
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.coverage').errors?.maxLimit">
                            The insured value is greater than the policy limit.
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="form-group">
                    <mat-label>{{'TrackingNumber'|translate}}</mat-label>
                    <input required matInput #trackingNumber formControlName="trackingNumber">
                    <mat-error *ngIf="formGroup.controls.generalInformation?.controls.trackingNumber?.errors">
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.required">
                            {{ 'trackingnumberrequired' | translate }}
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.invalidFormat">
                            Special characters or spaces are not allowed.
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.existed">
                            {{ 'trackingNumberReported' | translate }}
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.serverError">
                            {{ 'serverError' | translate }}
                        </span>
                    </mat-error>
                    <upsc-element-blocker *ngIf="isTrackingNumberValidating"></upsc-element-blocker>
                </mat-form-field>


                <mat-form-field class="form-group">
                    <mat-label>{{'ShipmentType' | translate}}</mat-label>
                    <mat-select required formControlName="shipmentType">
                        <mat-option value="" disabled selected>{{'reportShipment.selectShipmentType' |
                            translate}}</mat-option>
                        <mat-option *ngFor="let shipmentType of shipmentTypes" [value]="shipmentType.shipmentTypeCode">
                            {{ shipmentType.shipmentTypeName | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.shipmentType?.touched && formGroup.controls.generalInformation?.controls.shipmentType?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.shipmentType?.errors?.required">
                            <span>{{'reportShipment.shipmentTypeRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-container shipping-info" [formGroup]="formGroup.controls.shippingInformation">
                <h4 class="section-title">Shipping Information</h4>
                <div class="inner-container">

                    <mat-form-field class="form-group">
                        <mat-label>Company Name</mat-label>
                        <input matInput formControlName="companyName">
                    </mat-form-field>

                    <mat-form-field class="form-group">
                        <mat-label>Phone Number</mat-label>
                        <input matInput formControlName="phoneNumber" maxlength="15" autocomplete="off">
                        <mat-error *ngIf="formGroup.controls.shippingInformation?.controls.phoneNumber?.errors">
                            <span>Please enter a valid phone number.</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inner-container">
                    <mat-form-field class="form-group">
                        <mat-label>First Name</mat-label>
                        <input [required]='this.userService.isEUUser(user.CountryCode)' matInput
                            formControlName="firstName">
                        <mat-error
                            *ngIf="formGroup.controls.shippingInformation?.controls.firstName.touched && formGroup.controls.shippingInformation?.controls.firstName.invalid">
                            <span *ngIf="formGroup.controls.shippingInformation?.controls.firstName.errors?.required">
                                <span>First name is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-group">
                        <mat-label>Last Name</mat-label>
                        <input [required]='this.userService.isEUUser(user.CountryCode)' matInput
                            formControlName="lastName">
                        <mat-error
                            *ngIf="formGroup.controls.shippingInformation?.controls.lastName.touched && formGroup.controls.shippingInformation?.controls.lastName.invalid">
                            <span *ngIf="formGroup.controls.shippingInformation?.controls.lastName.errors?.required">
                                <span>Last name is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field class="form-group">
                    <mat-label>Ship To Zip Code</mat-label>
                    <input [required]='this.userService.isEUUser(user.CountryCode)' matInput
                        formControlName="shipToZipCode">
                    <mat-error
                        *ngIf="formGroup.controls.shippingInformation?.controls.shipToZipCode.touched && formGroup.controls.shippingInformation?.controls.shipToZipCode.invalid">
                        <span>Invalid Zip Code</span>
                    </mat-error>
                </mat-form-field>

                <div class="inner-container">

                    <mat-form-field class="form-group">
                        <mat-label>Province</mat-label>
                        <input matInput formControlName="province">
                    </mat-form-field>

                    <mat-form-field class="form-group">
                        <mat-label>City</mat-label>
                        <input matInput [required]='this.userService.isEUUser(user.CountryCode)' formControlName="city">
                        <mat-error
                            *ngIf="formGroup.controls.shippingInformation?.controls.city?.touched && formGroup.controls.shippingInformation?.controls.city.invalid">
                            <span *ngIf="formGroup.controls.shippingInformation?.controls.city?.errors?.required">
                                <span>Ship To City is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inner-container">
                    <mat-form-field class="form-group">
                        <mat-label>Weight</mat-label>
                        <input matInput formControlName="weight">
                        <mat-error
                            *ngIf="formGroup.controls.shippingInformation?.controls.weight?.hasError('positiveNumber')">
                            Please enter a valid number.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-group">
                        <mat-label>Reference Number</mat-label>
                        <input matInput formControlName="referenceNumber">
                    </mat-form-field>

                </div>

            </div>
        </div>

        <div class="form-checkbox-container" *ngIf="!isEditingExistingData">

            <mat-checkbox class="checkbox" formControlName="isAgreeToTerms">
                I have read, understand, agree, and full compiled with ParcelPro’s <a href="javascript:;"
                    (click)="openTermsAndConditionsDialog($event)"> {{'reportShipment.terms2'|translate}} </a>
            </mat-checkbox>

            <button type="submit" mat-raised-button [disabled]="!isFormValid()">Submit</button>

        </div>

        <div class="question-container" *ngIf="!isEditingExistingData">
            <h4>Having Questions?</h4>
            <upsc-s3-file-downloader [bucketName]="'members-docx.upsc'" [buttonText]="'Single Report Upload FAQ'"
                [fileName]="'Single_Report_Upload_FAQ.docx'" [showDownloadIcon]="true"></upsc-s3-file-downloader>
            <hr>
        </div>
    </form>

    <form [formGroup]="formGroup" class="report-shipments-edit-upload" *ngIf="isEditingExistingData"
        (ngSubmit)="onFormSubmit($event,formGroup.value)">
        <div class="edit-form-wrapper">
            <div class="form-container edit-sections" [formGroup]="formGroup.controls.generalInformation">
                <h4 class="section-title">General Information</h4>

                <mat-form-field class="edit-form-group">
                    <mat-label>{{'ShipDate' | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="shipDate" required [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.touched && formGroup.controls.generalInformation?.controls.shipDate?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.errors?.required">
                            <span>{{'reportShipments.ShipDateRequired' | translate }}</span>
                        </span>
                        <span
                            *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.errors?.matDatepickerMin">
                            <span>{{'reportShipments.ShipDateMinError' | translate}}</span>
                        </span>
                        <span
                            *ngIf="formGroup.controls.generalInformation?.controls.shipDate?.errors?.matDatepickerMax">
                            <span>{{'.reportShipments.ShipDateMaxError' | translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>{{'Carrier' | translate}}</mat-label>
                    <mat-select required formControlName="carrier">
                        <mat-option value="" disabled selected>{{'reportShipment.selectCarrier'|translate}}</mat-option>
                        <mat-option *ngFor="let carrier of carriers" [value]="carrier.CarrierCode">
                            {{ carrier.CarrierCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.carrier?.touched && formGroup.controls.generalInformation?.controls.carrier?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.carrier?.errors?.required">
                            <span>{{'reportShipment.carrierRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isCarriersLoading"></upsc-element-blocker>

                <mat-form-field class="edit-form-group">
                    <mat-label>{{'ServiceType'|translate}}</mat-label>
                    <mat-select required formControlName="serviceType">
                        <mat-option value="" selected>{{'reportShipment.selectServiceType'|translate}}</mat-option>
                        <mat-option *ngFor="let service of filteredServices" [value]="service.ServiceCode">
                            {{ service.Description || service.ServiceCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.serviceType?.touched && formGroup.controls.generalInformation?.controls.serviceType?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.serviceType?.errors?.required">
                            <span>{{'ServiceTypeRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>{{'TrackingNumber'|translate}}</mat-label>
                    <input required matInput #trackingNumber formControlName="trackingNumber">
                    <mat-error *ngIf="formGroup.controls.generalInformation?.controls.trackingNumber?.errors">
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.required">
                            {{ 'trackingnumberrequired' | translate }}
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.invalidFormat">
                            Special characters or spaces are not allowed.
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.existed">
                            {{ 'trackingNumberReported' | translate }}
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors?.serverError">
                            {{ 'serverError' | translate }}
                        </span>
                    </mat-error>
                    <upsc-element-blocker *ngIf="isTrackingNumberValidating"></upsc-element-blocker>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>Coverage</mat-label>
                    <input required matInput formControlName="coverage">
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.coverage.touched && formGroup.controls.generalInformation?.controls.coverage.invalid">
                        <span class="long-error" *ngIf="formGroup.get('generalInformation.coverage').errors?.required">
                            The insured value cannot be zero, negative, or blank.
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.coverage').errors?.numeric">
                            The insured value must be numeric.
                        </span>
                        <span class="long-error" *ngIf="formGroup.get('generalInformation.coverage').errors?.positive">
                            The insured value cannot be zero, negative, or blank.
                        </span>
                        <span *ngIf="formGroup.get('generalInformation.coverage').errors?.maxLimit">
                            The insured value is greater than the policy limit.
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Country</mat-label>
                    <mat-select required formControlName="shipToLocation">
                        <mat-option value="" disabled selected>Select Ship To location </mat-option>
                        <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                            {{ country.CountryName }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.shipToLocation?.touched && formGroup.controls.generalInformation?.controls.shipToLocation.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.shipToLocation?.errors?.required">
                            <span>Ship To location is required</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Country</mat-label>
                    <mat-select required formControlName="shipFromLocation">
                        <mat-option value="" disabled selected>{{'reportShipment.selectShipFromlocation' |
                            translate}}</mat-option>
                        <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                            {{ country.CountryName }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.shipFromLocation?.touched && formGroup.controls.generalInformation?.controls.shipFromLocation?.invalid">
                        <span
                            *ngIf="formGroup.controls.generalInformation?.controls.shipFromLocation?.errors?.required">
                            <span>Ship From location is required</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>{{'ShipmentType' | translate}}</mat-label>
                    <mat-select required formControlName="shipmentType">
                        <mat-option value="" disabled selected>{{'reportShipment.selectShipmentType' |
                            translate}}</mat-option>
                        <mat-option *ngFor="let shipmentType of shipmentTypes" [value]="shipmentType.shipmentTypeCode">
                            {{ shipmentType.shipmentTypeName | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="formGroup.controls.generalInformation?.controls.shipmentType?.touched && formGroup.controls.generalInformation?.controls.shipmentType?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.shipmentType?.errors?.required">
                            <span>{{'reportShipment.shipmentTypeRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <div [formGroup]="formGroup.controls.shippingInformation">
                    <mat-form-field class="edit-form-group">
                        <mat-label>Weight</mat-label>
                        <input matInput formControlName="weight">
                        <mat-error
                            *ngIf="formGroup.controls.shippingInformation?.controls.weight?.hasError('positiveNumber')">
                            <span>Please enter a valid number.</span>
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="form-container edit-sections" [formGroup]="formGroup.controls.shipToAddress">
                <h4 class="section-title">Ship To Address</h4>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Company Name</mat-label>
                    <input matInput formControlName="shipToCompanyName">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Attention</mat-label>
                    <input matInput formControlName="shipToAttention">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Address 1</mat-label>
                    <input matInput formControlName="shipToAddress1">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Address 2</mat-label>
                    <input matInput formControlName="shipToAddress2">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Address 3</mat-label>
                    <input matInput formControlName="shipToAddress3">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To City</mat-label>
                    <input matInput formControlName="shipToCity">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>State / Province</mat-label>
                    <input matInput formControlName="shipToStateProvince">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Postal Code</mat-label>
                    <input matInput formControlName="shipToZipCode">
                    <mat-error *ngIf="formGroup.controls.shipToAddress?.controls.shipToZipCode?.errors">
                        <span>Please enter a valid zip code.</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Phone</mat-label>
                    <input matInput formControlName="shipToPhone" maxlength="15" autocomplete="off">
                    <mat-error *ngIf="formGroup.controls.shipToAddress?.controls.shipToPhone?.errors">
                        <span>
                            Please enter a valid phone number.
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>To Email</mat-label>
                    <input matInput formControlName="shipToEmail">
                    <mat-error *ngIf="formGroup.controls.shipToAddress?.controls.shipToEmail?.errors">
                        <span>
                            Please enter a valid email address.
                        </span>
                    </mat-error>
                </mat-form-field>

            </div>

            <div class="form-container edit-sections" [formGroup]="formGroup.controls.shipFromAddress">
                <h4 class="section-title">Ship From Address</h4>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Company Name</mat-label>
                    <input matInput formControlName="shipFromCompanyName">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Attention</mat-label>
                    <input matInput formControlName="shipFromAttention">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Address 1</mat-label>
                    <input matInput formControlName="shipFromAddress1">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Address 2</mat-label>
                    <input matInput formControlName="shipFromAddress2">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Address 3</mat-label>
                    <input matInput formControlName="shipFromAddress3">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From City</mat-label>
                    <input matInput formControlName="shipFromCity">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>State / Province</mat-label>
                    <input matInput formControlName="shipFromStateProvince">
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Postal Code</mat-label>
                    <input matInput formControlName="shipFromZipCode">
                    <mat-error *ngIf="formGroup.controls.shipFromAddress?.controls.shipFromZipCode?.errors">
                        <span>Please enter a valid zip code.</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Phone</mat-label>
                    <input matInput formControlName="shipFromPhone" maxlength="15" autocomplete="off">
                    <mat-error *ngIf="formGroup.controls.shipFromAddress?.controls.shipFromPhone?.errors">
                        <span>
                            Please enter a valid phone number.
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="edit-form-group">
                    <mat-label>From Email</mat-label>
                    <input matInput formControlName="shipFromEmail">
                    <mat-error *ngIf="formGroup.controls.shipFromAddress?.controls.shipFromEmail?.errors">
                        <span>
                            Please enter a valid email address.
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-template>